import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "./timecardadditem.css";

// This dialogue is for editing hours and notes on a timecard item
const TimecardDialog = (props) => {
  const {
    open,
    timeItem,
    handleDialogClose,
    handleDelete,
    variant,
    submitEdit,
  } = props;

  const [currentItem, setCurrentItem] = useState({ ...timeItem });

  useEffect(() => {
    setCurrentItem(timeItem);
  }, [timeItem]);

  const handleChange = (e) => {
    const itemChanged = e.target.name;
    const type = e.target.type;
    let value =
      type === "number"
        ? Number(parseFloat(e.target.value).toFixed(1))
        : e.target.value;
    setCurrentItem({
      ...currentItem,
      [itemChanged]: value,
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        {variant === "delete"
          ? "Delete the following item from your timecard?"
          : variant === "edit"
          ? "Enter Changes: "
          : ""}
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => handleDialogClose()}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" noWrap>
          {currentItem.project}
        </Typography>
        <Typography variant="body1" component="div" sx={{ mt: 0 }} noWrap>
          {currentItem.package_name}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {currentItem.activity_name}
        </Typography>
        <Typography
          variant="subtitle2"
          align="left"
          display={variant === "delete" ? "block" : "none"}
        >
          ST: {currentItem.hours_st}{" "}
          {currentItem.hours_ot > 0 && "OT:" + currentItem.hours_ot}{" "}
          {currentItem.hours_dt > 0 && "DT:" + currentItem.hours_dt}
        </Typography>
        {variant === "edit" ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                mt: 1,
                mb: 1,
              }}
            >
              {/* <TextField
                id="hours_st"
                label="ST"
                onChange={handleChange}
                variant="outlined"
                name="hours_st"
                type="number"
                value={currentItem.hours_st}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: ".5" }}
                sx={{ ml: { md: 1 }, width: 75 }}
              /> */}
              <NumericTextBoxComponent
                id="hours_st"
                name="hours_st"
                cssClass={"e-style"}
                decimals={1}
                format="n1"
                width="85px"
                min={0}
                value={currentItem.hours_st}
                step={0.5}
                placeholder="ST"
                floatLabelType="Always"
                onChange={handleChange}
                htmlAttributes={{ inputMode: "decimal", pattern: "[0-9]*" }}
              ></NumericTextBoxComponent>
              {/* <TextField
                id="hours_ot"
                label="OT"
                onChange={handleChange}
                variant="outlined"
                name="hours_ot"
                type="number"
                value={currentItem.hours_ot}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: ".5" }}
                sx={{ ml: 1, width: 75 }}
              /> */}
              <NumericTextBoxComponent
                id="hours_ot"
                name="hours_ot"
                cssClass={"e-style"}
                decimals={1}
                format="n1"
                width="85px"
                min={0}
                value={currentItem.hours_ot}
                step={0.5}
                placeholder="OT"
                floatLabelType="Always"
                onChange={handleChange}
                htmlAttributes={{ inputMode: "decimal", pattern: "[0-9]*" }}
              ></NumericTextBoxComponent>
              {/* <TextField
                id="hours_dt"
                label="DT"
                onChange={handleChange}
                variant="outlined"
                name="hours_dt"
                type="number"
                value={currentItem.hours_dt}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: ".5" }}
                sx={{ ml: 1, width: 75 }}
              /> */}
              <NumericTextBoxComponent
                id="hours_dt"
                name="hours_dt"
                cssClass={"e-style"}
                decimals={1}
                format="n1"
                width="85px"
                min={0}
                value={currentItem.hours_dt}
                step={0.5}
                placeholder="DT"
                floatLabelType="Always"
                onChange={handleChange}
                htmlAttributes={{ inputMode: "decimal", pattern: "[0-9]*" }}
              ></NumericTextBoxComponent>
            </Box>
          </Box>
        ) : (
          ""
        )}
        {(variant === "edit" || variant === "notes") && (
          <TextField
            id="notes"
            label="Notes"
            onChange={handleChange}
            variant="outlined"
            name="notes"
            type="text"
            value={currentItem.notes}
            fullWidth
            multiline
            sx={{ mt: 3 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleDialogClose()}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() =>
            variant === "delete" ? handleDelete() : submitEdit(currentItem)
          }
        >
          {variant === "delete" ? "Delete" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimecardDialog;
