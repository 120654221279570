import { useState, useEffect, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import TimeCardAddItem from "./TimecardAddItem";
import TimecardItem from "./TimecardItem";
import TimecardDialog from "./TimecardDialog";
import AlertDialog from "../utilities/AlertDialog";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridActionsCellItem,
  GridFooter,
  GridFooterContainer,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  format,
  isSunday,
  nextSunday,
  isSaturday,
  previousFriday,
  subDays,
  addDays,
} from "date-fns";

export const Timecard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("delete");
  const [currentTimeItem, setCurrentTimeItem] = useState({
    project_name: "",
    package_name: "",
    activity_name: "",
    hours_st: 0,
    hours_ot: 0,
    hours_dt: 0,
  });
  const [activityOptions, setActivityOptions] = useState([]);
  const [activePackages, setActivePackages] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [timeCardUser, setTimeCardUser] = useState("");
  const [timecards, setTimecards] = useState([]);
  const [timecardsWeek, setTimecardsWeek] = useState([]);
  const [timeCardDate, setTimeCardDate] = useState(
    format(new Date(), "yyyy/MM/dd")
  );
  const [weekEnd, setWeekEnd] = useState(
    isSunday(new Date())
      ? format(new Date(), "yyyy/MM/dd")
      : format(nextSunday(new Date(timeCardDate)), "yyyy/MM/dd")
  );
  const [weekStart, setWeekStart] = useState(
    isSaturday(new Date()) || isSunday(new Date())
      ? format(previousFriday(subDays(new Date(timeCardDate), 4)), "yyyy/MM/dd")
      : format(previousFriday(new Date(timeCardDate)), "yyyy/MM/dd")
  );

  const auth = useContext(AuthContext);

  const dispatch = useDispatch();

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
      timeout: 3000,
    }),
    [auth.token]
  );

  // this is for the notes icon on the datagrid
  const renderDetailsButton = (params) => {
    if (params.row.notes) {
      return (
        <Tooltip title={params.row.notes} placement="top-start" arrow>
          <StickyNote2OutlinedIcon
            color="action"
            onClick={() => {
              handleEditNote(params.row);
            }}
          />
        </Tooltip>
      );
    }
  };

  const getWorkItem = (params) => {
    return `${params.row.project} /${params.row.package_name} /${params.row.activity_name}`;
  };

  const columns = [
    {
      field: "work_item",
      headerName: "Work Item",
      flex: 1,
      valueGetter: getWorkItem,
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "package_name",
      headerName: "Package",
      flex: 1,
    },
    {
      field: "activity_name",
      headerName: "Activity",
      flex: 0.5,
    },
    {
      field: "hours_st",
      headerName: "ST",
      flex: 0.1,
      editable: true,
    },
    {
      field: "hours_ot",
      headerName: "OT",
      flex: 0.1,
      editable: true,
    },
    {
      field: "hours_dt",
      headerName: "DT",
      flex: 0.1,
      editable: true,
    },
    {
      field: "notes",
      headerName: "",
      align: "right",
      renderCell: renderDetailsButton,
      flex: 0.1,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.3,
      headerName: "",
      cellClassName: "actions",
      getActions: (row) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          name="edit"
          label="Edit"
          onClick={() => {
            handleEdit(row.row);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="action" />}
          label="Delete"
          onClick={() => {
            handleConfirmDelete(row.row);
          }}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<StickyNote2OutlinedIcon />}
          name="notes"
          label="Add Note"
          onClick={() => {
            handleEditNote(row.row);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Box
          sx={{
            justifyContent: "left",
            display: "flex",
          }}
        >
          Total Hours:{"  "}
          {timecards.reduce((accumulator, object) => {
            return accumulator + object.hours_st;
          }, 0)}
          {"  "}
          ST{"  "}
          {timecards.reduce((accumulator, object) => {
            return accumulator + object.hours_ot;
          }, 0)}
          {"  "}
          OT{"  "}
          {timecards.reduce((accumulator, object) => {
            return accumulator + object.hours_dt;
          }, 0)}
          {"  "}
          DT
        </Box>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
            justifyContent: "left",
          }}
        />
      </GridFooterContainer>
    );
  };

  // this only runs at the beginning of loading the timecard and gets all the info.
  useEffect(() => {
    const getTimecardOptions = async () => {
      try {
        const result = await axios.get(
          "/api/timecards/timecardoptions",
          requestConfig
        );
        const usersSent = result.data.timecardUsers;
        setActivityOptions(result.data.activityOptions);
        setActivePackages(result.data.activePackages);
        setActiveProjects(result.data.activeProjects);
        setUsers(usersSent);

        if (usersSent.length === 1) {
          setTimeCardUser(usersSent[0]);
        }
        console.log("Here is the timecard information:", result.data);
        return;
      } catch (err) {
        dispatch(
          alertActions.showAlert({
            title: "Error Getting Timecard Info",
            message:
              "The following error occured while retrieving the timecard data: " +
              err.message,
          })
        );
        console.log("error getting timecard data: ", err);
      }
    };
    getTimecardOptions();
  }, [requestConfig]);

  // this will get the new timecards anytime the user or the week is changed
  useEffect(() => {
    const getTimeCards = async () => {
      const requestData = {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
        params: {
          user_id: timeCardUser.id,
          date_beg: weekStart,
          date_end: weekEnd,
        },
      };

      let timecardRequest;
      try {
        const response = await axios.get(
          "/api/timecards/getusertimerange",
          requestData
        );

        timecardRequest = response.data.timecard_items;
        console.log(
          "Here are the timecard line items for the week: ",
          timecardRequest
        );
        setTimecardsWeek(timecardRequest);

        const currentTimeItems = timecardRequest.filter(
          (timeItem) =>
            format(new Date(timeItem.date), "yyyy/MM/dd") === timeCardDate
        );

        console.log("Here are the current date time items: ", currentTimeItems);
        setTimecards(currentTimeItems);
        setIsLoading(false);
        return;
      } catch (err) {
        console.log("There was an error getting the timecard: ", err);
        dispatch(
          alertActions.showAlert({
            title: "Error Getting Timecards",
            message:
              "The following error occured when getting the new timecards: " +
              err.message,
          })
        );
        return;
      }
    };

    if (timeCardUser) {
      setIsLoading(true);
      getTimeCards();
    }
  }, [weekEnd, timeCardUser, timeCardDate, weekStart, auth.token]);

  // this adds a new line item to the timecard
  const addTimeItem = async (newTimecard) => {
    console.log("Here is the time being added: ", newTimecard);
    const time_id = `${timeCardDate.replaceAll("/", "-")}/${
      timeCardUser.lastname
    }:${timeCardUser.firstname}/${newTimecard.project.number}/${
      newTimecard.package.package_id
    }/${newTimecard.activity.activity_name}`;

    if (timecards.filter((e) => e.id === time_id).length > 0) {
      dispatch(
        alertActions.showAlert({
          title: "Item Already Exists",
          message:
            "This item already exists in the timecard.  Please update the values in the current item",
        })
      );
      return;
    }

    let notes = null;

    // this checks if the project is a note project and adds the day as a note
    if (newTimecard.project.number.slice(0, 1) === "A") {
      const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      const d = new Date(timeCardDate);
      notes = weekday[d.getDay()];
    }

    const newItem = {
      id: time_id,
      date: timeCardDate,
      user_id: timeCardUser.id,
      user_metal_trade: timeCardUser.metal_trade,
      project_id: newTimecard.project.id,
      project: `${newTimecard.project.number} - ${newTimecard.project.name}`,
      project_number: newTimecard.project.number,
      project_name: newTimecard.project.name,
      package_id: newTimecard.package.package_id,
      package_name: newTimecard.package.package_name,
      package_cat_id: newTimecard.package.package_cat_id,
      activity_id: newTimecard.activity.activity_id,
      activity_name: newTimecard.activity.activity_name,
      hours_st: Number(newTimecard.hours_st),
      hours_ot: Number(newTimecard.hours_ot),
      hours_dt: Number(newTimecard.hours_dt),
      notes: notes,
    };

    // This function calculates the correct index to insert the new time item in the timecards for the day and for the week.
    const lastIndexOf = (array, packageId, projectId) => {
      for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].package_id === packageId) return i + 1;
      }
      for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].project_id === projectId) return i + 1;
      }
      return -1;
    };

    const indexInsertDay = lastIndexOf(
      timecards,
      newTimecard.package.package_id,
      newTimecard.project.id
    );
    let newTimecardsDay = [...timecards];
    newTimecardsDay.splice(indexInsertDay, 0, newItem);

    const oldTimecardsDay = timecards;

    setTimecards(newTimecardsDay);

    const indexInsertWeek = lastIndexOf(
      timecardsWeek,
      newTimecard.package.package_id,
      newTimecard.project.id
    );
    let newTimecardsWeek = [...timecardsWeek];
    newTimecardsWeek.splice(indexInsertWeek, 0, newItem);

    const oldTimecardsWeek = timecardsWeek;

    setTimecardsWeek(newTimecardsWeek);

    const data = {
      timecard_item: [newItem],
    };

    try {
      // 2.13.25 I modified this function because users were not getting notified when the data was not getting saved on the server
      // so I added a timeout function to the requestConfig.  Then I check for different error messages and display them to the user.  Then
      // I set the user back to blank so that it forcess the user to reselect their name thus reloading the current data from the server.

      const serverResponse = await axios.post(
        "/api/timecards/addtimecarditem",
        data,
        requestConfig
      );
      const serverTimecards = serverResponse.data.addedTimecards;
      console.log(
        "Here is the server response for the new timecard: ",
        serverTimecards
      );
    } catch (err) {
      // console.log(err);
      console.log("There was an error adding the timecard: ", err);

      let errorMessage;

      if (err.code === "ECONNABORTED") {
        errorMessage =
          "The server did not respond.  Please check your network connection and try again";
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (err.message?.includes("Network Error")) {
        errorMessage =
          "Could not connect to the server. Check your internet connection.";
      } else {
        errorMessage = "An unknown error occured.";
      }

      dispatch(
        alertActions.showAlert({
          title: "Error Adding Timecard",
          message:
            "The server encountered the following error while adding the timecard: " +
            errorMessage,
        })
      );

      setTimeCardUser("");
      // setTimecards(oldTimecardsDay);
      // setTimecardsWeek(oldTimecardsWeek);
    }
  };

  const handleUserWeekChange = async (newValue, itemChanged) => {
    console.log("The item that was changed is: ", itemChanged);

    let dateSelected;

    if (itemChanged === "user") {
      setTimeCardUser(newValue);
    } else if (itemChanged === "timeDate") {
      dateSelected = newValue;
      setTimeCardDate(format(new Date(dateSelected), "yyyy/MM/dd"));
      if (
        (new Date(dateSelected) >= addDays(new Date(weekStart), 2)) &
        (new Date(dateSelected) <= new Date(weekEnd))
      ) {
        console.log("The date is in the current week");
        const currentTimeItems = timecardsWeek.filter(
          (timeItem) =>
            format(new Date(timeItem.date), "yyyy/MM/dd") ===
            format(new Date(dateSelected), "yyyy/MM/dd")
        );
        setTimecards(currentTimeItems);
      } else {
        console.log("Setting new Week for Timecard");
        setWeekStart(
          isSaturday(new Date(dateSelected)) || isSunday(new Date(dateSelected))
            ? format(
                previousFriday(subDays(new Date(dateSelected), 4)),
                "yyyy/MM/dd"
              )
            : format(previousFriday(new Date(dateSelected)), "yyyy/MM/dd")
        );
        setWeekEnd(
          isSunday(new Date(dateSelected))
            ? format(new Date(dateSelected), "yyyy/MM/dd")
            : format(nextSunday(new Date(dateSelected)), "yyyy/MM/dd")
        );
      }
    }
  };

  const handleValueChange = async (e) => {
    console.log(e);
    const fieldChanged = e.field;
    let newValue;
    let changeValue = true;
    const itemId = e.id;
    const packageChanged = e.row.package_name;
    const activityChanged = e.row.activity_name;

    console.log(e);

    if (fieldChanged !== "notes") {
      const categoryChanged = fieldChanged.replace("hours_", "");
      newValue = parseFloat(e.value);

      // This is going to check if there is a value already in the time.  If so it will ask the user to confirm the change before making it.
      const oldValue = parseFloat(e.row[fieldChanged]);
      console.log("Here is the old Value: ", oldValue);
      if (oldValue !== 0) {
        changeValue = window.confirm(
          `Do you want to change ${packageChanged} ${activityChanged} ${categoryChanged} from ${oldValue} hours to ${newValue} hours?`
        );
      }
    } else {
      newValue = e.value;
    }

    const timecardUpdate = (time) => {
      const response = time.map((timecard) => {
        if (timecard.id === itemId) {
          return {
            ...timecard,
            [fieldChanged]: newValue,
          };
        } else {
          return timecard;
        }
      });
      return response;
    };

    if (changeValue) {
      const oldTimecard = [...timecards];
      const newTimecard = timecardUpdate(timecards);
      setTimecards(newTimecard);

      const oldTimecardsWeek = [...timecardsWeek];
      const newTimecardsWeek = timecardUpdate(timecardsWeek);
      setTimecardsWeek(newTimecardsWeek);

      let timecardResponse;

      const data = {
        itemId: itemId,
        fieldChanged: fieldChanged,
        newValue: newValue,
      };

      try {
        timecardResponse = await axios.post(
          "/api/timecards/updatetimecard",
          data,
          requestConfig
        );
        console.log(timecardResponse);
      } catch (err) {
        if (err.response) {
          console.log("Error updating Timecard: ", err);
        }
        dispatch(
          alertActions.showAlert({
            title: "Error Updating Timecard",
            message:
              "The server encountered the following error while updating the timecard: " +
              err.response.data.message,
          })
        );
        setTimecards(oldTimecard);
        setTimecardsWeek(oldTimecardsWeek);
      }
    } else {
      setTimecards([...timecards]);
    }
  };

  const handleEdit = (item) => {
    setDialogType("edit");
    setCurrentTimeItem(item);
    setDialogOpen(true);
  };

  const handleEditNote = (item) => {
    console.log("Selected to edit note: ", item);
    setDialogType("notes");
    setCurrentTimeItem(item);
    setDialogOpen(true);
  };

  const submitEdit = async (changedItem) => {
    console.log("Here is the changed Item: ", changedItem);

    const oldTimecards = [...timecards];
    const newTimecards = timecards.map((timecard) => {
      if (timecard.id === changedItem.id) {
        return changedItem;
      } else {
        return timecard;
      }
    });
    setTimecards(newTimecards);

    const oldTimecardsWeek = [...timecardsWeek];

    const newTimecardsWeek = timecardsWeek.map((timecard) => {
      if (timecard.id === changedItem.id) {
        return changedItem;
      } else {
        return timecard;
      }
    });

    setTimecardsWeek(newTimecardsWeek);

    setDialogOpen(false);

    let timecardResponse;

    const data = {
      changedItem: changedItem,
    };

    try {
      timecardResponse = await axios.post(
        "/api/timecards/updatetimecardall",
        data,
        requestConfig
      );
      console.log(timecardResponse);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
      }

      dispatch(
        alertActions.showAlert({
          title: "Error Updating Timecard",
          message:
            "The server encountered the following error while updating the timecard: " +
            err.response.data.message,
        })
      );
      setTimecards(oldTimecards);
      setTimecardsWeek(oldTimecardsWeek);
    }

    setCurrentTimeItem({
      project_name: "",
      package_name: "",
      activity_name: "",
      hours_st: 0,
      hours_ot: 0,
      hours_dt: 0,
    });
  };

  const handleConfirmDelete = (item) => {
    setDialogType("delete");
    setCurrentTimeItem(item);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    setDialogOpen(false);
    const deletedId = currentTimeItem.id;

    const timecardsBefore = timecards;
    const timecardsWeekBefore = timecardsWeek;

    const requestData = {
      headers: {
        Authorization: "Bearer " + auth.token,
      },
      params: {
        time_id: deletedId,
      },
    };

    setTimecards(timecards.filter((timecard) => timecard.id !== deletedId));
    setTimecardsWeek(
      timecardsWeek.filter((timecard) => timecard.id !== deletedId)
    );
    try {
      const response = await axios.delete(
        "/api/timecards/deletetimecard",
        requestData
      );
      console.log(response);
    } catch (err) {
      console.log("Error deleting timecard: ", err);
      dispatch(
        alertActions.showAlert({
          title: "Error Deleting Timecard",
          message:
            "The server encountered the following error while deleting the timecard: " +
            err.response.data.message,
        })
      );
      setTimecards(timecardsBefore);
      setTimecardsWeek(timecardsWeekBefore);
    }
  };


  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentTimeItem({
      project_name: "",
      package_name: "",
      activity_name: "",
      hours_st: 0,
      hours_ot: 0,
      hours_dt: 0,
    });
  };

  const handleSupervision = async () => {
    const data = {
      user: timeCardUser,
      date: timeCardDate,
    };

    try {
      const response = await axios.post(
        "/api/timecards/addsupervision",
        data,
        requestConfig
      );
      console.log(response.data);
      const { addedTimecards, remainingTime } = response.data;
      console.log(addedTimecards);
      console.log(remainingTime);
      setTimecards([...timecards, ...addedTimecards]);
      setTimecardsWeek([...timecardsWeek, ...addedTimecards]);
      const responseMessage =
        remainingTime === 0
          ? "All supervision hours were allocated to the items below."
          : remainingTime > 0
          ? `Please note there was ${remainingTime} that were not allocated.  Please add this to one of the below items`
          : `Please note there was an extra ${
              -1 * remainingTime
            } allocated to the items below.  Please remove this amount from one of the items`;

      dispatch(
        alertActions.showAlert({
          title: "Supervision Added",
          message: responseMessage,
        })
      );
    } catch (err) {
      console.log(err);
      console.log(
        "There was an error adding supervision to the timecard: ",
        err
      );
      dispatch(
        alertActions.showAlert({
          title: "Error Adding Supervision",
          message:
            "The server encountered the following error while adding the supervision time: " +
            err.response.data.message,
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "95%" },
        height: "100%",
        paddingTop: 2,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <AlertDialog />
      <Box
        sx={{
          width: { xs: "90%", md: "25%" },
          maxWidth: { xs: 500, md: 300 },
          height: "100%",
          paddingTop: 2,
          paddingLeft: { xs: 3, md: 5 },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormControl
          sx={{
            paddingBottom: 2,
          }}
        >
          <InputLabel id="user-label">User</InputLabel>
          <Select
            labelId="user-label"
            id="user"
            value={timeCardUser}
            name="user"
            label="User"
            onChange={(e) => {
              handleUserWeekChange(e.target.value, e.target.name);
            }}
            sx={{ height: 50, width: "100%" }}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user}>
                {`${user.firstname} ${user.lastname}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <LocalizationProvider name="timeDate" dateAdapter={AdapterDateFns}>
            <Box
              sx={{
                width: "100%",
                display: { xs: "block", md: "none" },
              }}
            >
              <DatePicker
                label="Timecard Date"
                value={timeCardDate}
                onChange={(newValue) => {
                  handleUserWeekChange(newValue, "timeDate");
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                sx={{ height: 50, width: "100%" }}
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={timeCardDate}
                size="small"
                name="timeDate"
                onChange={(newValue) => {
                  handleUserWeekChange(newValue, "timeDate");
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>
      {timeCardUser ? (
        <Box
          sx={{
            width: { xs: "90%", md: "75%" },
            paddingLeft: { xs: 3, md: 5 },
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              paddingBottom: { xs: 0, md: 15 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: auth.isAdmin ? { xs: "100%", md: "90%" } : "100%",
                }}
              >
                <TimeCardAddItem
                  activityOptions={activityOptions}
                  activeProjects={activeProjects}
                  activePackages={activePackages}
                  addTimeItem={addTimeItem}
                />
              </Box>
              {auth.isAdmin && (
                <Button
                  variant="outlined"
                  sx={{
                    display: { xs: "none", md: "block" },
                    ml: 2,
                    height: "50%",
                    width: "10%",
                  }}
                  onClick={handleSupervision}
                >
                  Superv
                </Button>
              )}
            </Box>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <DataGrid
                    columnVisibilityModel={{
                      work_item: false,
                    }}
                    getRowId={(row) => row.id}
                    rows={timecards}
                    columns={columns}
                    onCellEditCommit={handleValueChange}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    components={{ Footer: CustomFooter }}
                    autoHeight
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    display: { xs: "block", md: "none" },
                  }}
                  color="text.secondary"
                  align="right"
                >
                  Total Hours:{"  "}
                  {timecards.reduce((accumulator, object) => {
                    return accumulator + object.hours_st;
                  }, 0)}
                  {"  "}
                  ST{"  "}
                  {timecards.reduce((accumulator, object) => {
                    return accumulator + object.hours_ot;
                  }, 0)}
                  {"  "}
                  OT{"  "}
                  {timecards.reduce((accumulator, object) => {
                    return accumulator + object.hours_dt;
                  }, 0)}
                  {"  "}
                  DT
                </Typography>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    height: "40vh",
                    overflowY: "scroll",
                    background: "#f5f5f5",
                  }}
                >
                  <TimecardDialog
                    open={dialogOpen}
                    variant={dialogType}
                    timeItem={currentTimeItem}
                    handleDialogClose={handleDialogClose}
                    handleDelete={handleDelete}
                    submitEdit={submitEdit}
                  />
                  {timecards.map((timecard) => (
                    <TimecardItem
                      key={timecard.id}
                      timeItem={timecard}
                      handleDelete={handleConfirmDelete}
                      handleEdit={handleEdit}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};
