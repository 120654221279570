import { useState, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import { AuthContext } from "./context/auth-context";
import stratus from '../Resources/stratus.png'

// temporarily hiding timecards and user until completed

export const Nav = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const auth = useContext(AuthContext);
  const user =
    Array.from(auth.userFirstName)[0] + Array.from(auth.userLastName)[0];

  const isAdmin = auth.isAdmin;

  console.log("Inside nav bar and isAdmin is: ", isAdmin);

  const pages = isAdmin
    ? [
        "Projects",
        "Packages",
        "Schedule",
        "Users",
        "Inventory",
        "Timecards",
        "Week Timecards",
        "Reports",
      ]
    : ["Timecards"];
  const settings = ["Logout", "Change Password"];

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    const page = e.target.id;
    console.log("Close Nav Menu this is the page: ", page);
    console.log(e);

    if (page === "Projects") {
      navigate("/projects");
    } else if (page === "Packages") {
      navigate("/packages");
    } else if (page === "Schedule") {
      navigate("/schedule");
    } else if (page === "Users") {
      navigate("/users");
    } else if (page === "Inventory") {
      navigate("/inventory/vmi/orders");
    } else if (page ==="Timecards"){
      navigate("timecards");
    } else if (page ==="Week Timecards"){
      navigate("timecardsweek");
    } else if (page === "Weld Inches") {
      navigate("weldinchesreport");
    } else if (page === "Reports") {
      navigate("reports");
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (e) => {
    const userFunction = e.target.id;
    console.log("Close user menu for logout :", userFunction);
    if (userFunction === "Logout") {
      auth.logout();
    } else if (userFunction === "Change Password") {
      navigate("changepassword");
    }
    setAnchorElUser(null);
  };

  return (
    <div>
      {/* Changed the app bar back. The following was for the syncfusion banner */}
      {/* <AppBar position="sticky" sx={{minHeight:100, paddingTop:{ xs: 15, md: 5 }}}> */}
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <HomeIcon />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} id={page} onClick={handleCloseNavMenu}>
                    <Typography key={page} id={page} textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              P1
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  id={page}
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="https://www.gtpstratus.com/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  // fontWeight: 700,
                  
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Box
                  href="https://www.gtpstratus.com/"
                  component="img"
                  sx={{
                    height: 30,
                  }}
                  alt="STRATUS"
                  src={stratus}
                />
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{user}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    id={setting}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography id={setting} textAlign="center">
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </div>
  );
};
